.doctor-list-style-none {
    list-style: none;
    list-style-position: outside;
}

.doctor-list-style-none li {
    padding-bottom: 10px;
    color: #191667;
}

.doctor-profile-page-name {
    color: #191667;
    font-family: "Raleway", sans-serif;
}

.doctor-profile-page li {
    font-family: "Open Sans", sans-serif;
}