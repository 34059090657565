.pricing__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fafafa;
}

.pricing__container-card {
	
	height: 480px;
	text-decoration: none;
	border-radius: 4px;
}



.pricing__container-card:hover {
	transform: scale(1.06);
	transition: all 0.3s ease-out;
	color: #1c2237;
}

.pricing__wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 0 auto;
}

.pricing__heading {
	color: #1c2237;
	margin-bottom: 24px;
}

.bannervid-container {
	position: relative; /* Ensure the container's position is relative */
    width: 100%;
	height: 410px;
	box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
}

.bannervid-container video {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.pricing__container-cardInfo {
	display: flex;
	flex-direction: column;
	height: 440px;
	padding: 24px;
	align-items: center;
	color: #fff;
	box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
}

.pricing__container-cardInfo h3 {
	margin-bottom: 5px;
	font-size: 24px;
}

.pricing__container-cardInfo h4 {
	font-size: 40px;
}

.pricing__container-cardInfo p {
	font-size: 14px;
	margin-bottom: 24px;
}

.pricing__container-features {
	margin: 16px 0 32px;
	list-style: none;
}

.pricing__container-features li {
	margin-bottom: 10px;
}

.pricing__container-features {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.icon {
	margin: 24px 0;
}


.twenty-one-banner-vid {
	cursor: pointer;
}

@media screen and (max-width: 960px) {
	.pricing__container-card {
		width: 90%;
	}

	.pricing__wrapper {
		margin: 0 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.pricing__container-card:hover {
		transform: none;
	}
}